import { defineStore } from "pinia";

import {
  Invoice,
  InvoiceSingle,
  InvoiceStatus,
  InvoicePaymentStatus,
  InvoiceDateTypes,
} from "~/types/invoices";
import {
  Meta,
  ResponseType,
  Option,
  Filters,
  SyncStatus,
} from "~/types/general";
import { CustomerSingle } from "~/types/customers";
import { useOrdersStore } from "./orders";
import { useTasksStore } from "~/store/tasks";

export const useInvoicesStore = defineStore("invoices", () => {
  // State
  const invoices = ref<Invoice[]>([]);
  const meta = ref<Meta>({
    current_page: 1,
    per_page: 50,
  });
  const pageTable = ref(1);
  const perPageTable = ref(10);

  const viewingInvoice = ref<InvoiceSingle>({} as InvoiceSingle);
  const viewingInvoiceCustomer = ref<CustomerSingle>({} as CustomerSingle);

  const isOpenSidebar = ref(false);
  const editMode = ref(false);
  const isGuestFilter = ref(false);

  const statuses = ref<Option[]>([
    { name: "Active", value: InvoiceStatus.ACTIVE },
    { name: "Awaiting Payment", value: InvoiceStatus.AWAITING_PAYMENT },
    { name: "Paid", value: InvoiceStatus.PAID },
    { name: "Overdue", value: InvoiceStatus.OVERDUE },
    { name: "Draft", value: InvoiceStatus.DRAFT },
  ]);

  const paymentStatuses = ref<Option[]>([
    { name: "Unpaid", value: InvoicePaymentStatus.UNPAID },
    { name: "Paid", value: InvoicePaymentStatus.PAID },
  ]);

  const dateTypes = ref<Option[]>([
    { name: "Creation Date", value: InvoiceDateTypes.CREATION },
    { name: "Target Date", value: InvoiceDateTypes.TARGET },
  ]);

  // Filters
  const filters = ref<Filters>({
    statuses: {
      type: "statuses",
      title: "Status",
      options: statuses.value,
      chosenOptions: [],
    },
    types: {
      type: "types",
      title: "Order Type",
      options: [],
      chosenOptions: [],
    },
    orderStatus: {
      type: "orderStatus",
      title: "Order Status",
      options: useOrdersStore().statusTags,
      chosenOptions: [],
    },
    customers: {
      type: "customers",
      title: "Customers",
      options: [],
      chosenOptions: [],
    },
    sync_statuses: {
      type: "sync_statuses",
      title: "Sync Status",
      options: [
        { name: "Pending", value: SyncStatus.PENDING },
        { name: "Synced", value: SyncStatus.SYNCED },
        { name: "Error", value: SyncStatus.ERROR },
      ],
      chosenOptions: [],
    },
    creditService: {
      type: "creditService",
      title: "Credit Service",
      options: [],
      chosenOptions: [],
      toggle: false,
      isSingle: true,
    },
    payment_statuses: {
      type: "payment_statuses",
      title: "Payment Statuses",
      options: [
        { name: "Unpaid", value: InvoicePaymentStatus.UNPAID },
        { name: "Credit", value: InvoicePaymentStatus.CREDIT },
        { name: "Paid", value: InvoicePaymentStatus.PAID },
        { name: "Refunded", value: InvoicePaymentStatus.REFUNDED },
        { name: "Gifted", value: InvoicePaymentStatus.GIFTED },
      ],
      chosenOptions: [],
    },
    payment_type: {
      type: "payment_type",
      title: "Payment Types",
      options: [],
      chosenOptions: [],
    },
    target_date: {
      type: "target_date",
      title: "Target Date",
      options: [],
      chosenOptions: [],
      isSingle: true,
    },
    payment_date: {
      type: "payment_date",
      title: "Payment Date",
      options: [],
      chosenOptions: [],
      isSingle: true,
    },
    customersLetter: {
      type: "customersLetter",
      title: "Alphabet letter",
      options: [],
      chosenOptions: [],
      isSingle: true,
    },
  });

  const selectedDate = ref("");
  const selectedDateRange = ref({ start: "", end: "" });
  const targetDateRange = ref([]);
  const selectedRowsIds = ref([]);
  const paymentDateRange = ref([]);
  const baseTableKey = ref(0);

  // Getters
  const customerFilterOptions = computed<Option[]>(() => {
    const customers = invoices.value.map((statement) => ({
      name: statement.customer?.name,
      value: statement.customer?.id,
    }));

    return [
      {
        name: "Guest",
        value: null,
      },
      ...new Map(customers.map((item) => [item.value, item])).values(),
    ].filter((customer) => customer.name);
  });

  // Actions - Fetch Invoices
  async function getInvoices(params?): Promise<ResponseType<Invoice[]>> {
    try {
      const response = await useVaniloApi("/business", { params });
      return response as ResponseType<Invoice[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getSingleInvoice(
    id: number
  ): Promise<ResponseType<InvoiceSingle>> {
    try {
      const response = await useVaniloApi(`/business/${id}`);
      return response as ResponseType<InvoiceSingle>;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Actions - Sync Invoices
  async function batchSyncInvoice(body, storeId?: number) {
    useIsLoading(true);
    try {
      const response = await useVaniloApi(
        `/xero/batch-sync-invoices/${storeId}`,
        {
          method: "POST",
          body,
        }
      );

      return response;
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function bulkSyncInvoicesByIds(body, storeId?: string) {
    useIsLoading(true);
    try {
      const response = (await useVaniloApi(
        `/xero/bulk-sync-invoices/${storeId}`,
        {
          method: "POST",
          body,
        }
      )) as ResponseType<any>;

      useTasksStore().taskId = response.data.id;

      return response;
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    } finally {
      useIsLoading(false);
    }
  }

  // Actions - Send Notifications
  async function bulkInvoicesSendNotification(payload?) {
    let response: any;
    const tasksStore = useTasksStore();
    try {
      response = await useVaniloApi(`/orders/bulk-notify`, {
        method: "POST",
        body: {
          ...(selectedRowsIds.value.length && {
            orders: selectedRowsIds.value,
          }),
          notification: payload,
        },
      });
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }
    tasksStore.taskId = response.data.id;
    selectedRowsIds.value = [];
    return response;
  }

  // Actions - Export Invoices
  async function bulkExportInvoicesByIds(body) {
    useIsLoading(true);
    try {
      const response = await useVaniloApi(`/business/bulk-export`, {
        method: "POST",
        body,
      });
      const blob = new Blob([response as BlobPart], { type: "text/plain" });

      const blobUrl = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = `export-business-invoice-items-report.txt`;
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(blobUrl);

      return { url: blobUrl };
    } catch (error) {
      console.log(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function bulkExportInvoicePaymentsByIds(body) {
    useIsLoading(true);
    try {
      const response = await useVaniloApi(`/business/bulk-export-payments`, {
        method: "POST",
        body,
      });
      const blob = new Blob([response as BlobPart], { type: "text/plain" });

      const blobUrl = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = `export-business-invoice-payments-report.txt`;
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(blobUrl);

      return { url: blobUrl };
    } catch (error) {
      console.log(error);
    } finally {
      useIsLoading(false);
    }
  }

  // Actions - Clear Filters
  function clearFilters() {
    for (let d in filters.value) {
      filters.value[d].chosenOptions = [];
    }
  }

  return {
    invoices,
    meta,
    pageTable,
    perPageTable,
    viewingInvoice,
    viewingInvoiceCustomer,
    isOpenSidebar,
    editMode,
    statuses,
    paymentStatuses,
    dateTypes,
    filters,
    selectedDate,
    selectedDateRange,
    targetDateRange,
    selectedRowsIds,
    paymentDateRange,
    baseTableKey,
    customerFilterOptions,
    isGuestFilter,
    getInvoices,
    getSingleInvoice,
    batchSyncInvoice,
    bulkSyncInvoicesByIds,
    bulkInvoicesSendNotification,
    bulkExportInvoicesByIds,
    bulkExportInvoicePaymentsByIds,
    clearFilters,
  };
});
